<template>
  <div>
    <AlertWraper
      class="text-start"
      :content="errors"
      type="error"
    />
    <form class="default-form mt-4 position-relative" @submit.prevent="submit">
      <LoaderOverlay
        size="big"
        color="color2"
        v-if="overlayLoader"
      />
      <div class="text-start">
        <div class="d-lg-flex">
          <div class="col-lg-6 px-0 pe-lg-3">
            <div class="form-group">
              <label class="form-label fw-bold">Tipo de conta</label>
              <div class="custom-select">
                <select
                    class="form-control default"
                    v-model.trim="$v.bank_account.type.$model"
                  >
                    <option value="conta_corrente">Conta Corrente</option>
                    <option value="conta_poupanca">Conta Poupança</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-lg-6 px-0">
            <div class="form-group">
              <label class="form-label fw-bold">Banco</label>
              <Select2
                class="default-style"
                :options="banksOptions"
                @input="selectBank"
                placeholder="Selecione um banco"
              />
            </div>
          </div>
        </div>
        <div class="d-lg-flex">
          <div class="col-lg-6 px-0 pe-lg-3">
            <div class="form-group ">
              <label class="form-label fw-bold">Agência</label>
              <input type="text" class="form-control" v-model.trim="$v.bank_account.agency.$model" placeholder="" />
            </div>
          </div>
          <div class="col-lg-6 px-0">
            <div class="form-group">
              <label class="form-label fw-bold">Dígito da agência</label>
              <input type="text" class="form-control" v-model.trim="bank_account.agency_digit" placeholder="" />
            </div>
          </div>
        </div>
        <div class="d-lg-flex">
          <div class="col-lg-6 px-0 pe-lg-3">
            <div class="form-group">
              <label class="form-label fw-bold">Conta</label>
              <input type="text" class="form-control" v-model.trim="$v.bank_account.account.$model" placeholder="" />
            </div>
          </div>
          <div class="col-lg-6 px-0">
            <div class="form-group">
              <label class="form-label fw-bold">Dígito da conta</label>
              <input type="text" class="form-control" v-model.trim="$v.bank_account.digit.$model" placeholder="" />
            </div>
          </div>
        </div>
        <div class="d-lg-flex">
          <div class="col-lg-6 px-0 pe-lg-3">
            <div class="form-group">
              <label class="form-label fw-bold">Categoria de conta</label>
              <select
                class="form-control"
                v-model.trim="$v.bank_account.owner_document_type.$model"
              >
                <option value="cnpj">Conta Pessoa Jurídica</option>
              </select>
            </div>
          </div>
          <div class="col-lg-6 px-0">
            <div class="form-group">
              <label class="form-label fw-bold">{{ ownerDocumentLabel }}</label>
              <TheMask
                :mask="ownerDocumentMask"
                v-model.trim="$v.bank_account.owner_document.$model"
                placeholder=""
                class="form-control with-label"
                :readonly="true"
              />
            </div>
          </div>
        </div>
        <div>
          <div class="">
            <div class="form-group ">
              <label class="form-label fw-bold">{{ ownerNameLabel }}</label>
              <input type="text" class="form-control with-label" v-model.trim="$v.bank_account.owner_name.$model" placeholder="" />
            </div>
          </div>
        </div>
        <div class="mt-3 text-center text-color2 font-size-xSmall">
          <p><strong>Importante:</strong> Para contas em bancos digitais em que o dígito da agência não é expressamente informado, geralmente o valor é "0". Pedimos que consulte seu banco para confirmar.</p>
          <p><strong>Importante:</strong> A conta bancária precisa estar no mesmo nome e CNPJ do cadastro da empresa.</p>
        </div>
      </div>
      <div class="mt-3 text-center">
        <Button
          type="submit"
          class="btn-primary"
          :disabled="$v.$invalid"
          :loading="loader"
        >
          Criar conta
        </Button>
      </div>
    </form>
  </div>
</template>

<script>

import BankAccountsService from '@/modules/bank-accounts/services/bank-accounts-service'
import { mapState } from 'vuex'
import { parseErrorResponseToArray } from '@/utils'
import { required } from 'vuelidate/lib/validators'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'
import LoaderOverlay from '@/components/common/Loader/LoaderOverlay'
import Select2 from '@/components/common/Select/Select2'
import { TheMask } from 'vue-the-mask'

export default {
  name: 'BankAccountCreationForm',
  components: {
    AlertWraper,
    Button,
    LoaderOverlay,
    Select2,
    TheMask
  },
  data () {
    return {
      errors: null,
      loader: false,
      overlayLoader: false,
      banks: [],
      bank_account: {
        bank: '',
        agency: '',
        agency_digit: '',
        account: '',
        digit: '',
        type: 'conta_corrente',
        owner_name: '',
        owner_document: '',
        owner_document_type: 'cnpj'
      }
    }
  },
  validations () {
    return {
      bank_account: {
        bank: { required },
        agency: { required },
        account: { required },
        digit: { required },
        type: { required },
        owner_name: { required },
        owner_document: { required },
        owner_document_type: { required }
      }
    }
  },
  created () {
    this.getBanks()
    if (this.user) this.bank_account.owner_document = this.user.company.document
  },
  watch: {
    user (newUser) {
      this.bank_account.owner_document = newUser.company.document
    }
  },
  computed: {
    banksOptions () {
      return this.banks.map(bank => {
        return {
          label: `${bank.name} (${bank.code})`,
          code: bank.id
        }
      })
    },
    ownerDocumentMask () {
      if (this.bank_account.owner_document_type === 'cpf') return '###.###.###-##'
      else return '##.###.###/####-##'
    },
    ownerDocumentLabel () {
      if (this.bank_account.owner_document_type === 'cpf') return 'CPF do proprietário'
      else return 'CNPJ do proprietário'
    },
    ownerNameLabel () {
      if (this.bank_account.owner_document_type === 'cpf') return 'Nome do proprietário'
      else return 'Razão social do proprietário'
    },
    ...mapState('users', {
      user: state => state.user
    })
  },
  methods: {
    /**
     * Get banks
     */
    async getBanks () {
      this.overlayLoader = true
      try {
        const banks = await BankAccountsService.getBanks()
        this.banks = banks.data
        // this.bank_account.bank = banks.data[0].id
      } catch (error) {
        this.errors = parseErrorResponseToArray(error)
      }
      this.overlayLoader = false
    },
    /**
     * Reset form
     */
    reset () {
      this.bank_account.bank = ''
      this.bank_account.agency = ''
      this.bank_account.agency_digit = ''
      this.bank_account.account = ''
      this.bank_account.digit = ''
      this.bank_account.type = ''
      this.bank_account.owner_name = ''
      this.bank_account.owner_document = ''
      this.bank_account.owner_document_type = ''
    },
    /**
     * Handle select bank
     */
    selectBank (bank) {
      this.bank_account.bank = bank.code
    },
    /**
     * Submit form
     */
    async submit () {
      this.loader = true
      this.errors = null
      try {
        const account = await BankAccountsService.createBankAccount(this.bank_account)
        this.reset()
        this.$emit('bank-account-created', account)
      } catch (error) {
        this.errors = parseErrorResponseToArray(error)
      }
      this.loader = false
    }
  }
}
</script>
