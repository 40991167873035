<template>
  <div>
    <div class="d-flex justify-content-between align-items-center mb-5">
      <div>
        <h1 class="highlighted-color1">
          <span>Financeiro</span>
        </h1>
      </div>
      <div>
        <CreateOrderButton
          v-if="user"
          :reloadAdvertisers="reloadAdvertisers"
          @order-created="reloadOrders"
        />
        <UpsertAdvertiserModal
          @advertiser-updated="reloadAdvertisers = true"
          @advertiser-created="reloadAdvertisers = true"
          v-if="user"
        />

      </div>
    </div>
    <div class="text-center mt-4" v-if="loader && !user">
      <Loader
        :size="'big'"
      />
    </div>
    <div class="mt-4 col-lg-10 mx-auto" v-else>
      <b-tabs content-class="mt-4" nav-class="default-tabs">
        <!-- EXTRATO TAB -->
        <b-tab title="Minha carteira" v-if="
          user &&
          user.company.type === 'AGENCY' &&
          walletsSummary &&
          (walletsSummary.amount_available_for_payment > 0 || walletsSummary.amount_frozen > 0)
        ">
          <!-- AVAILABLE -->
          <section class="mb-5" v-if="walletsSummary">
            <div class="d-flex card p-3 flex-row justify-content-center align-items-center">
              <div class="d-flex justify-content-center flex-grow-1 mr-2 font-size-small mr-4">
                <span class="text-color2">
                  <i class="fa fa-money me-1"></i> Saldo disponível:
                </span>
                <span class="ml-2">{{ walletsSummary.amount_available_for_payment/100 | formatMoney }}</span>
              </div>
              <div class="d-flex justify-content-center flex-grow-1 font-size-small mr-4">
                <span class="text-color2">
                  <i class="fa fa-money text-color2 me-1"></i> Saldo a receber:
                </span>
                <span class="ml-2">{{ walletsSummary.amount_frozen/100 | formatMoney }}</span>
              </div>
              <div class="">
                <Button
                  class="btn btn-primary btn-sm"
                  :disabled="walletsSummary.amount_available_for_payment <= 0"
                  v-b-modal.create-wallet-checkout-modal
                >
                  Realizar saque
                </Button>
              </div>
            </div>
            <CreateWalletCheckoutModal
              @wallet-checkout-created="getWallets"
            />
          </section><!-- END AVAILABLE -->
          <!-- FILTER -->
          <section class="mb-4">
            <div class="d-flex align-items-center justify-content-center">
              <div class="d-flex text-center align-items-center justify-content-center font-size-small default-form me-3">
                Filtrar de
                <DateTimeInput
                  v-model.trim="walletStart"
                  format="dd/MM/yyyy"
                  type="date"
                  theme="basic"
                  class="mx-2 form-control-sm-wrapper"
                />
                até
                <DateTimeInput
                  v-model.trim="walletEnd"
                  format="dd/MM/yyyy"
                  type="date"
                  theme="basic"
                  class="mx-2 form-control-sm-wrapper"
                />
              </div>
              <div>
                <Button
                  class="btn-primary btn-xs"
                  @click="resetWallets"
                >
                  Pesquisar
                </Button>
              </div>
            </div>
          </section> <!-- END FILTER -->
          <!-- CONTENT -->
          <section>
            <div class="text-center" v-if="walletsLoader && wallets.length === 0">
              <Loader
                :size="'big'"
              />
            </div>
            <div class="" v-else>
              <div v-if="wallets.length === 0">
                <p class="text-center">Não foram encontradas transações</p>
              </div>
              <div v-else>
                <div class="card">
                  <div class="card-body">
                    <div class="mb-4">
                      <p>
                        <strong>Total de entradas:</strong> {{ walletsSummary.credit_amount / 100 | formatMoney }}<br/>
                        <strong>Total de saídas:</strong> {{ walletsSummary.debit_amount / 100 | formatMoney }}
                      </p>
                    </div>
                    <b-tabs content-class="mt-4" nav-class="default-tabs">
                      <!-- HISTORY TAB -->
                      <b-tab title="Histórico">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Data</th>
                              <th>Descrição</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(wallet, index) in pastWallets" :key="index">
                              <td>{{ wallet.payment_date | formatDate }}</td>
                              <td>{{ wallet.description }}</td>
                              <td>
                                {{ wallet.type | formatWalletTypeToSignal }}
                                {{ wallet.amount / 100 | formatMoney }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </b-tab> <!-- END HISTORY TAB -->
                      <!-- FUTURE HISTORY TAB -->
                      <b-tab title="Lançamentos futuros">
                        <table class="table table-striped">
                          <thead>
                            <tr>
                              <th>Data</th>
                              <th>Descrição</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(wallet, index) in futureWallets" :key="index">
                              <td>{{ wallet.payment_date | formatDate }}</td>
                              <td>{{ wallet.description }}</td>
                              <td>
                                {{ wallet.type | formatWalletTypeToSignal }}
                                {{ wallet.amount / 100 | formatMoney }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </b-tab> <!-- END FUTURE HISTORY TAB -->
                    </b-tabs>
                  </div>
                </div>
                <div class="text-center mt-4">
                  <Button
                    class="btn-primary btn-sm"
                    :loading="walletsLoader"
                    @click="loadMoreWallets"
                    v-if="isLoadMoreWalletsVisible"
                  >
                    Carregar mais
                  </Button>
                </div>
              </div>
            </div>
          </section><!-- END CONTENT -->
        </b-tab> <!-- END EXTRATO TAB -->
        <!-- BANK ACCOUNT TAB -->
        <b-tab title="Conta de recebimento" v-if="
          user &&
          user.company.type === 'AGENCY' &&
          walletsSummary &&
          (walletsSummary.amount_available_for_payment > 0 || walletsSummary.amount_frozen > 0)
        ">
          <div class="text-center" v-if="accountLoader">
            <Loader
              :size="'big'"
            />
          </div>
          <div class="" v-else>
            <p class="text-center"><strong>Essa é a conta bancária que utilizaremos para depositar o cashback das compras de anunciantes.</strong></p>
            <div v-if="account">
              <BankAccountCard
                :account="account"
              />
            </div>
            <div v-else>
              <p class="text-center">Nenhuma conta encontrada</p>
            </div>
            <div class="text-center mt-4">
              <Button
                class="btn btn-primary"
                v-b-modal.create-bank-account-modal
              >
                {{ account ? 'Trocar conta bancária' : 'Criar conta bancária' }}
              </Button>
              <CreateBankAccountModal
                @created-bank-account="getBankAccount"
              />
            </div>
          </div>
        </b-tab> <!-- END BANK ACCOUNT TAB -->
        <!-- PAYMENTS TAB -->
        <b-tab title="Movimentações de verba">
          <div>
            <OrderListTable />
          </div>
        </b-tab> <!-- END PAYMENTS TAB -->
      </b-tabs>
    </div>
  </div>
</template>

<script>

import BankAccountsService from '@/modules/bank-accounts/services/bank-accounts-service'
import { mapState } from 'vuex'
import moment from 'moment'
import WalletsService from '@/modules/wallets/services/wallets-service'
import variables from '@/mixins/variables'

import BankAccountCard from '@/components/bank-accounts/BankAccountCard'
import Button from '@/components/common/Button/Button'
import CreateBankAccountModal from '@/components/bank-accounts/CreateBankAccountModal'
import CreateOrderButton from '@/components/orders/CreateOrderButton'
import CreateWalletCheckoutModal from '@/components/wallets/CreateWalletCheckoutModal'
import DateTimeInput from '@/components/common/Input/DateTimeInput'
import Loader from '@/components/common/Loader/Loader'
import UpsertAdvertiserModal from '@/components/advertisers/UpsertAdvertiserModal'
import OrderListTable from '@/components/orders/OrderListTable'

export default {
  mixins: [variables],
  components: {
    BankAccountCard,
    Button,
    CreateBankAccountModal,
    CreateOrderButton,
    CreateWalletCheckoutModal,
    DateTimeInput,
    Loader,
    OrderListTable,
    UpsertAdvertiserModal
  },
  data () {
    return {
      account: null,
      accountLoader: false,
      isLoadMoreWalletsVisible: false,
      loader: true,
      reloadAdvertisers: false,
      tabIndex: 0,
      wallets: [],
      walletsSummary: null,
      walletsLoader: false,
      walletStart: '',
      walletEnd: '',
      walletsPage: 1
    }
  },
  metaInfo: {
    title: 'Financeiro'
  },
  created () {
    this.walletStart = moment({ hour: 0, minute: 0, seconds: 0 }).subtract(60, 'days').format()
    this.walletEnd = moment({ hour: 0, minute: 0, seconds: 0 }).add(60, 'days').format()
    if (this.user) {
      this.checkIfUserIsManager()
      this.getContent()
    }
  },
  watch: {
    user (newUser) {
      this.checkIfUserIsManager()
      this.getContent()
    },
    walletStart (newValue, oldValue) {
      // const newV = moment(newValue)
      // const oldV = moment(oldValue)
      // if (!newV.isSame(oldV) && this.user) {
      //   this.wallets = []
      //   this.getWallets()
      // }
    },
    walletEnd (newValue, oldValue) {
      // const newV = moment(newValue)
      // const oldV = moment(oldValue)
      // if (!newV.isSame(oldV) && this.user) {
      //   this.wallets = []
      //   this.getWallets()
      // }
    }
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    }),
    /**
     * Future wallets entries
     */
    futureWallets () {
      const now = moment()
      return this.wallets.filter(item => {
        const date = moment(item.payment_date)
        return date.isAfter(now)
      })
    },
    /**
     * Past wallets entries
     */
    pastWallets () {
      const now = moment()
      return this.wallets.filter(item => {
        const date = moment(item.payment_date)
        return date.isSameOrBefore(now)
      })
    }
  },
  methods: {
    /**
     * Check if user is manager or not
     */
    checkIfUserIsManager () {
      if (this.user.role.slug !== 'user-manager' && this.user.role.slug !== 'administrator') this.$router.push('/')
    },
    /**
     * Get main bank account
     */
    async getBankAccount () {
      this.accountLoader = true
      try {
        const account = await BankAccountsService.getPrimaryBankAccount({ companyId: this.user.company.id })
        this.account = account
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.accountLoader = false
    },
    /**
     * Get page content
     */
    getContent () {
      // Set active tab depending on type of company
      if (this.user.company.type !== 'AGENCY') this.tabIndex = 2
      this.getWallets()
      this.getBankAccount()
    },
    /**
     * Get wallets
     */
    async getWallets () {
      this.walletsLoader = true
      try {
        const wallets = await WalletsService.getWallets({
          start_date: moment(this.walletStart).format('YYYY-MM-DD'),
          end_date: moment(this.walletEnd).format('YYYY-MM-DD'),
          companyId: this.user.company.id,
          page: this.walletsPage
        })
        this.wallets = [...this.wallets, ...wallets.data]
        this.isLoadMoreWalletsVisible = wallets.meta.current_page !== wallets.meta.last_page
        this.walletsSummary = wallets.additional_info
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.walletsLoader = false
    },
    /**
     * Load more orders
     */
    loadMoreWallets () {
      this.walletsPage++
      this.getWallets()
    },
    /**
     * Reload orders
     */
    reloadOrders () {
      this.orders = []
      this.getOrders()
    },
    resetWallets () {
      this.wallets = []
      this.walletsPage = 1
      this.getWallets()
    }
  }
}
</script>
