<template>
  <b-modal
    id="create-wallet-checkout-modal"
    :modal-class="'default-modal'"
    :hide-footer="true"
    @hidden="hidden"
    @shown="shown"
  >
    <template v-slot:default>
      <AlertWraper
        :content="errors"
        type="error"
      />
      <p class="text-center font-size-small">
        Para que o saque seja realizado no mesmo dia, ele deve ser feito até às <strong>15h</strong>. Após esse horário, o valor será transferido para sua conta bancária no próximo dia útil.
      </p>
      <div>
        <div
          class="text-center"
          v-if="loader && available === null"
        >
          <Loader
            color="color2"
            size="big"
          />
        </div>
        <div v-else-if="available !== null">
          <!-- SELECT AMOUNT -->
          <div class="text-center">
            <h2 class="font-weight-bold font-size-regular mb-3 highlighted-color1">
              <span>Escolha o valor a sacar</span>
            </h2>
          </div>
          <p class="font-size-small">Valor mínimo de saque: <strong>R$ 5,00</strong></p>
          <div class="d-flex mb-2 align-items-center">
            <div class="col-md-4 px-0 default-form">
              <Money
                class="form-control form-control-sm"
                v-model="$v.amount.$model"
                v-bind="money"
              />
            </div>
            <div class="col-md-8">
              <div class="align-self-center d-flex align-items-center justify-content-center">
                <div class="">
                  <span class="">
                    <i class="fa fa-money me-1"></i> Saldo disponível:
                  </span>
                  <span class="ml-2">{{ available/100 | formatMoney }}</span>
                </div>
              </div>
            </div>
          </div> <!-- END SELECT AMOUNT -->
          <p class="font-size-small text-center mb-4">
            <strong>Atenção:</strong> Será cobrada uma taxa de transferência do processador de pagamento de <strong>R$ 3,67</strong>. O valor depositado será de <strong>{{ amount > 3.67 ? (amount - 3.67) : 0 | formatMoney }}</strong>.
          </p>
          <!-- CONFIRM BANK ACCOUNT -->
          <div class="text-center">
            <h2 class="font-weight-bold font-size-regular mb-3 highlighted-color1">
              <span>Conta de recebimento</span>
            </h2>
          </div>
          <template
            v-if="account"
          >
            <p class="font-size-small">
              <span class="d-block">{{ account.owner_name }}</span>
              <span class="d-block">{{ account.owner_document | VMask(documentMask) }}</span>
              <span class="d-block">{{ account.bank.name }}</span>
              <span class="d-block">Agência: {{ `${account.agency}${account.agency_digit ? '-' + account.agency_digit : ''}` }}</span>
              <span class="d-block">Conta: {{ `${account.account}-${account.digit}` }}</span>
            </p>
            <p class="font-size-small text-center">
              <strong>Atenção:</strong> Para alterar a conta de recebimento, você deve alterar sua conta primária na aba <strong>Conta Bancária</strong>.
            </p>
          </template>
          <template v-else>
            <p class="text-center font-size-small">Não foi encontrada uma conta bancária cadastrada. Por favor, faça o cadastro na aba <strong>Conta Bancária</strong> para prosseguir.</p>
          </template>
          <!-- END BANK ACCOUNT -->
          <div class="mt-4 text-center">
            <Button
              @click="submit"
              class="btn btn-primary"
              :loading="loader"
              :disabled="$v.$invalid || available <= 0 || $v.amount.$model < 5 || amount > (available / 100)"
            >
              Solicitar saque
            </Button>
          </div>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>

import BankAccountsService from '@/modules/bank-accounts/services/bank-accounts-service'
import NotificationService from '@/modules/notifications/services/notifications-service'
import WalletsService from '@/modules/wallets/services/wallets-service'
import { mapState } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { parseErrorResponseToArray } from '@/utils'
import { mask } from 'vue-the-mask'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'
import { Money } from 'v-money'

export default {
  name: 'WalletCheckoutCreationModal',
  directives: { mask },
  components: {
    AlertWraper,
    Button,
    Loader,
    Money
  },
  data () {
    return {
      amount: '',
      available: null,
      account: null,
      errors: null,
      loader: false,
      money: {
        decimal: ',',
        thousands: '.',
        prefix: 'R$ ',
        precision: 2,
        masked: false
      }
    }
  },
  validations () {
    return {
      amount: { required }
    }
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    }),
    documentMask () {
      if (this.account.owner_document_type === 'cpf') return '###.###.###-##'
      return '##.###.###/####-##'
    }
  },
  methods: {
    /**
     * On modal hidden
    */
    hidden () {
      this.reset()
    },
    /**
     * Reset form
     */
    reset () {
      this.amount = ''
      this.available = null
      this.account = null
      this.errors = null
      this.loader = false
    },
    /**
     * On modal shown
     */
    async shown () {
      this.loader = true
      try {
        const account = await BankAccountsService.getPrimaryBankAccount({ companyId: this.user.company.id })
        this.account = account

        const amount = await WalletsService.getWalletAmounts({ companyId: this.user.company.id })
        this.available = amount.additional_info.amount_available_for_payment
      } catch (error) {
        console.log(error)
        this.errors = parseErrorResponseToArray(error)
      }
      this.loader = false
    },
    /**
     * Submit
     */
    async submit () {
      this.loader = true
      try {
        const wallet = await WalletsService.createCheckout({
          amount: (this.$v.amount.$model * 100).toFixed(0),
          companyId: this.user.company.id
        })
        this.$bvModal.hide('create-wallet-checkout-modal')
        const notification = {
          notification: {
            type: 'success',
            content: 'Solicitação de saque criada com sucesso'
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
        this.$emit('wallet-checkout-created', wallet)
      } catch (error) {
        this.errors = parseErrorResponseToArray(error)
      }
      this.loader = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
